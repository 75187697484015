<style lang="less">
.ivu-modal-header{ 
    border-bottom:none!important;
    padding: 14px 16px 0!important;
}
.ivu-modal-body{
    padding: 0 16px!important;
}
.ivu-modal-footer{
    border-top:none!important;
}
</style>
<template>
    <section>
        <Modal width="416" v-model="modalShow" title="请任选一种支付方式" :loading="loading" @on-cancel="closeWindow()">
            <div class="pay-container">
                <div ref="alipayForm" v-html="payFormPayData" id="alipayForm"></div>
                <button type="button" class="alipay ivu-btn ivu-btn-success" @click="pay(1)">
                    <span>
                        <svg aria-hidden="true" class="svg-icon" style="width: 25px; height: 25px">
                            <use xlink:href="#icon-alipay"></use>
                        </svg>
                        <span>支付宝</span>
                    </span>
                </button>
                <button type="button" class="wechat ivu-btn ivu-btn-success" @click="pay(2)">
                    <span>
                        <svg aria-hidden="true" class="svg-icon" style="width: 25px; height: 25px;">
                            <use xlink:href="#icon-wechat_pay"></use>
                        </svg>
                        <span>微信支付</span>
                    </span>
                </button>
            </div>
            <div slot="footer">
                <button type="button" class="ivu-btn ivu-btn-text" @click="closeWindow()">
                    <span>关闭</span>
                </button>
            </div>
        </Modal>
        <Modal v-model="wechatModal" title="微信扫码支付" :loading="loading" @on-cancel="closeWechatWindow()">
            <div class="mod-ct">
                <div class="order"></div>
                <div class="amount">￥{{ price }}</div>
                <div v-if="wechatOrderStatus">支付成功，即将跳转到订单页</div>
                <div v-else class="qr-image" id="qrcode" ref="qrCodeUrl"></div>
                <div class="detail" id="orderDetail">
                    <a href="javascript:void(0)" class="arrow"><i class="ico-arrow-wechat"></i></a>
                </div>
                <div class="tip">
                    <span class="dec dec-left"></span>
                    <span class="dec dec-right"></span>
                    <div class="ico-scan-wechat"></div>
                    <div class="tip-text">
                        <p>请使用微信扫一扫</p>
                        <p>扫描二维码完成支付</p>
                    </div>
                </div>
                <div class="tip-text">
                </div>
            </div>

            <div slot="footer">
                <button type="button" class="ivu-btn ivu-btn-text" @click="closeWechatWindow()">
                    <span>关闭</span>
                </button>
            </div>
        </Modal>
        <!-- 支付延迟转圈动画 -->
        <Spin fix v-if="isSpinShow">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>正在支付下单</div>
        </Spin>
    </section>
</template>

<script>
import { createOrder,checkWechatOrderStatus } from "@/api/pay.js";
import QRCode from 'qrcodejs2'

export default {
  name: 'payWindow',
  props: {
    modalShow:{
        type: Boolean,
        default:false
    },
    pid:{
        type: Number,
        default:0
    },
    scene:{
        type: Number,
        default:1
    }
  },
  data () {
    return {
        payFormPayData: {},             //支付宝-支付表单数据
        isSpinShow:0,                   //支付下单延迟转圈提示
        wechatModal:false,              //微信扫码支付弹窗面板
        price:0,                        //显示的订单价格
        checkWechatOrderStatusTimer:null, //查询微信订单支付状态定时器
        timerValue:3000,                //定时器时间间隔  单位 毫秒
        oid:'',                         //商户侧订单ID
        wechatOrderStatus:0,             //微信支付订单状态
        qrcode:null,                    //微信支付二维码对象
    }
  },
  created() {
    
  },
  watch: {
    payFormPayData() {
      this.$nextTick(function() {
        //console.log("UI渲染完成");
        //document.getElementById("alipayForm").submit();
        this.$refs.alipayForm.children[0].submit();
      });
    },
  },
  computed: {
    
  },
  methods: {
    pay(payType) {
        // 构造post数据
        const createOrderData = {
            pid: this.pid,
            payScene:this.scene,
            payType: payType,
        };
        //console.log(formData);
        //下单前打开spin转圈提示
        this.closeWindow()
        this.isSpinShow = 1;
        //调用接口下单
        createOrder(createOrderData).then(
            (res) => {
                //支付宝
                if(payType == 1){
                    this.payFormPayData = res.data;                     // 渲染支付页面
                    // 防抖避免重复支付
                    this.$nextTick(() => { 
                        this.isSpinShow = 0;
                        this.$refs.alipayForm.children[0].submit();     // 提交支付表单
                    });
                } else if(payType == 2){
                    this.isSpinShow = 0;
                    this.price = res.data.price
                    this.oid = res.data.oid
                    this.wechatModal = true
                    
                    this.$refs.qrCodeUrl.innerHTML = '';
                    this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
                        text: res.data.qrcode, // 需要转换为二维码的内容
                        width: 160,
                        height: 160,
                        colorDark: '#555',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    //微信支付-启动定时查询支付状态
                    this.checkWechatOrderStatusTimer = setInterval(()=>{
                        checkWechatOrderStatus({oid:this.oid}).then(orderStatus=>{
                            this.wechatOrderStatus = parseInt(orderStatus.data)
                            if(this.wechatOrderStatus == 1){
                                setTimeout(()=>{
                                    this.closeWechatWindow()
                                    this.$router.replace({ path: "/order" });
                                },2000)
                            }
                        })
                    },this.timerValue)
                }
            },
            (err) => {
                // this.isSpinShow = 0;
                // this.$store.commit("cart/EMPTY_GAME");
                // this.$Message.error({
                //     content: err.msg,
                //     onClose: function () {
                //     window.location.reload();
                //     },
                // });
            }
        );
    },
    closeWindow() {
        this.modalShow = false
        this.$emit('closePayWindow',false);
    },
    closeWechatWindow(){
        this.wechatModal = false
        clearInterval(this.checkWechatOrderStatusTimer)
    }
  },
  filters:{
    
  }
}
</script>
<style lang="less" scoped>

.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.pay-container {
	display: flex;
	justify-content: center;
}

.pay-container button {
	margin-top: 10px;
	height: 40px;
}

.pay-container .alipay {
	background: #2ba6df;
	border-color: #2ba6df;
	width: 140px;
	margin-right: 10px;
}

.pay-container .wechat {
	background: #28c445;
	border-color: #28c445;
	width: 140px;
	margin-left: 10px;
}

.pay-container button span {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
}

.pay-container .alipay span,.pay-container .wechat span {
	margin-left: 6px;
}

//wechat pay
.mod-ct {
    // width: 610px;
    padding: 0 35px;
    margin: 0 auto;
    margin-top: 15px;
    background: #fff url("@/assets/img/wave.png") top center repeat-x;
    text-align: center;
    color: #333;
    border: 1px solid #e5e5e5;
    border-top: none
}

.mod-ct .order {
    font-size: 20px;
    padding-top: 30px
}

.mod-ct .amount {
    font-size: 48px;
    margin-top: 20px;
    padding-right: 10px;
}

.mod-ct .qr-image {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.mod-ct .qr-image img {
    width: 230px;
    height: 230px;
}

.mod-ct .detail {
    margin-top: 30px;
    padding-top: 25px
}

.mod-ct .detail .arrow .ico-arrow-wechat {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/wechat-pay.png") -25px -100px no-repeat
}

.mod-ct .detail .arrow .ico-arrow-alipay {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/alipay-pay.png") -25px -100px no-repeat
}

.mod-ct .detail .detail-ct {
    display: none;
    font-size: 14px;
    text-align: right;
    line-height: 28px
}

.mod-ct .detail .detail-ct dt {
    float: left
}

.mod-ct .detail-open {
    border-top: 1px solid #e5e5e5
}

.mod-ct .detail .arrow {
    padding: 6px 34px;
    border: 1px solid #e5e5e5
}

.mod-ct .detail .arrow .ico-arrow {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/wechat-pay.png") -25px -100px no-repeat
}

.mod-ct .detail-open .arrow .ico-arrow {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/wechat-pay.png") 0 -100px no-repeat
}

.mod-ct .detail-open .detail-ct {
    display: block
}

.mod-ct .tip {
    margin-top: 40px;
    border-top: 1px dashed #e5e5e5;
    padding: 30px 0;
    position: relative
}

.mod-ct .tip .ico-scan-wechat {
    display: inline-block;
    width: 56px;
    height: 55px;
    background: url("@/assets/img/wechat-pay.png") 0 0 no-repeat;
    vertical-align: middle;
    *display: inline;
    *zoom: 1
}

.mod-ct .tip .ico-scan-alipay {
    display: inline-block;
    width: 56px;
    height: 55px;
    background: url("@/assets/img/alipay-pay.png") 0 0 no-repeat;
    vertical-align: middle;
    *display: inline;
    *zoom: 1
}
.mod-ct .tip .tip-text {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin-left: 23px;
    font-size: 16px;
    line-height: 28px;
    *display: inline;
    *zoom: 1
}

.mod-ct .tip .dec {
    display: inline-block;
    width: 22px;
    height: 45px;
    background: url("@/assets/img/wechat-pay.png") 0 -55px no-repeat;
    position: absolute;
    top: -23px
}

.mod-ct .tip .dec-left {
    background-position: 0 -55px;
    left: -36px
}

.mod-ct .tip .dec-right {
    background-position: -25px -55px;
    right: -36px
}
@media (max-width:768px) {
    .mod-ct {
        width: 100%;
        padding: 0
    }

    .mod-ct .tip .dec-right {
        display: none
    }

    .mod-ct .detail {
        margin-top: 30px
    }

    .mod-ct .detail .detail-ct {
        padding: 0 19px;
        margin-bottom: 19px
    }
}
</style>