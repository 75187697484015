import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const modulesFiles = require.context("./modules", true, /\.js$/);


//这个 modulesFiles 很奇怪 可参考https://zhuanlan.zhihu.com/p/59564277
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

//console.log(modules)

export default new Vuex.Store({
  modules,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  plugins: [createPersistedState()]
});
