import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "view-design";
Vue.use(VueRouter);

/* Layout */
import Layout from "@/layout/index";

/* 各模块路由 */

//import loginRouter from "./modules/login";
//import gamesRouter from "./modules/game";

const routes = [
  {
    path: "/",
    //name: "/",
    component: Layout,
    //redirect: "Home",
    children: [,
      {
        path: "/",
        component: () => import("@/views/home/index"),
        //name: "Home",
        meta: { title: "扳手数据开发省时网" }
      },
      {
        path: "detail",
        component: () => import("@/views/detail/index.vue"),
        name: "detail",
        meta: { title: "扳手数据开发省时网" }
      },
      {
        path: "cate",
        component: () => import("@/views/cate/index.vue"),
        name: "cate",
        meta: { title: "扳手数据开发省时网" }
      },
      {
        path: "course",
        component: () => import("@/views/doc/course"),
        name: "forgot",
        meta: { title: "扳手数据开发省时网" }
      },
      {
        path: "newbie",
        component: () => import("@/views/doc/newbie"),
        name: "forgot",
        meta: { title: "扳手数据开发省时网" }
      },
      {
        path: "fav",
        component: () => import("@/views/fav/index"),
        name: "fav",
        meta: { title: "扳手数据开发省时网", logged: true }
      },
      {
        path: "order",
        component: () => import("@/views/order/index"),
        name: "order",
        meta: { title: "扳手数据开发省时网", logged: true }
      },
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/user/login"),
    name: "login",
    meta: { title: "扳手数据开发省时网" }
  },
  {
    path: "/reg",
    component: () => import("@/views/user/reg"),
    name: "register",
    meta: { title: "扳手数据开发省时网" }
  },
  {
    path: "/forgot",
    component: () => import("@/views/user/forgotPassword"),
    name: "forgot",
    meta: { title: "扳手数据开发省时网" }
  },
  {
    path: "/wechatLoginSucc",
    component: () => import("@/views/user/wechatLoginSucc"),
    name: "wechatLoginSucc"
  },
  {
    path: "/wechatLoginFail",
    component: () => import("@/views/user/wechatLoginFail"),
    name: "wechatLoginFail"
  },
  {
    path: "/icon",
    name: "Icon",
    component: () => import("../components/icon.vue"),
    meta: { title: "icon图标" }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/privacy/index"),
    meta: { title: "扳手数据开发省时网" }
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: () => import("@/views/privacy/disclaimer"),
    meta: { title: "扳手数据开发省时网" }
  },
];

const router = new VueRouter({
  routes,
  mode: "history"
});

import { getToken } from "@/utils/auth";

router.beforeEach((to, from, next) => {
  // 判断有没有token
  const token = getToken();
  if (token) {
    if (to.path === "/login") {
      // 如果已登录， 则重定向
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    // 访问需要登录状态的页面
    if (to.meta.logged) {
      Message.error("您还未登录，请先登录!");
      next({ path: `/login?redirect=${to.path}` });
      return;
    } else {
      next();
    }
  }
  // 页面标题
  window.document.title = to.meta.title || to.name;
  next();
});

//解决新开页面后滚动条的位置停留在上个页面位置的问题
router.afterEach(() => {
  window.scrollTo(0, 0);
  document.querySelector("body").setAttribute("style", "overflow: auto !important;");
});

export default router;
