<template>
    <Submenu :name="menuList.name">
      <template slot="title">
        <!-- <Icon :type="menuList.icon" /> -->
        <span>{{ menuList.menu_title }}</span>
      </template>
      <template v-for="item in menuList.children">
        <left-menu-nav v-if="item.children&&item.children.length!==0" :menuList='item' :key='item.index'>
        </left-menu-nav>
        <menu-item v-else :key="item.id" :name="item.name" :to="item.to">
          {{item.menu_title}}
        </menu-item>
      </template>
    </Submenu>
  </template>
  <script>
  export default {
    name: 'leftMenuNav',
    props: {
      menuList: {
        type: Object,
        default: () => { }
      }
    },
    data () {
      return {
 
      }
    }
  }
  </script>
  <style lang="less" scoped>
  </style>
  
  
  