<template>
  <header class="header">
		<div class="header__wrap">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="header__content">
							<button class="header__menu" type="button">
								<span></span>
								<span></span>
								<span></span>
							</button>

							<a href="/" class="header__logo">
								<img src="@/assets/img/logo.png" alt="">
								<div class="header__logo_alt">
									<span class="en">DevSaveTime</span>
									<span class="zh">开发省时网</span>
								</div>
							</a>

							<ul class="header__nav">
								<li class="header__nav-item">
									<a class="header__nav-link" href="/">首页</a>
								</li>
								<li class="header__nav-item">
									<a class="header__nav-link" href="/cate" role="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">全部镜像</a>
								</li>
								<li class="header__nav-item">
									<a class="header__nav-link" href="/course" role="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">虚拟机教程</a>
								</li>
								<li class="header__nav-item">
									<a class="header__nav-link" href="/newbie" role="button" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">编程新手</a>
								</li>
								<!-- <li class="header__nav-item">
									<a class="header__nav-link" href="/senior">中级进阶</a>
								</li>
								<li class="header__nav-item">
									<a class="header__nav-link" href="/expert">高级技巧</a>
								</li> -->
							</ul>

							<div class="header__actions">
								<div class="header__lang">
									<a class="header__lang-btn" href="" role="button" id="dropdownMenuLang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<img src="@/assets/img/uk.svg" alt="">
										<span>EN</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M98,190.06,237.78,353.18a24,24,0,0,0,36.44,0L414,190.06c13.34-15.57,2.28-39.62-18.22-39.62H116.18C95.68,150.44,84.62,174.49,98,190.06Z"></path></svg>
									</a>

									<ul class="dropdown-menu header__lang-menu" aria-labelledby="dropdownMenuLang" style="">
										<li><a href=""><img src="@/assets/img/spain.svg" alt=""><span>1</span></a></li>
										<li><a href=""><img src="@/assets/img/russia.svg" alt=""><span>2</span></a></li>
										<li><a href=""><img src="@/assets/img/china.svg" alt=""><span>3</span></a></li>
									</ul>
								</div>
								
								<a v-if="empty(token)" href="/login" class="header__login">
									<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M192,176V136a40,40,0,0,1,40-40H392a40,40,0,0,1,40,40V376a40,40,0,0,1-40,40H240c-22.09,0-48-17.91-48-40V336" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></path><polyline points="288 336 368 256 288 176" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></polyline><line x1="80" y1="256" x2="352" y2="256" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></line></svg>
									<span>登录</span>
								</a>
								<template v-else>
									<a href="/order" class="header__login">
										<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M192,176V136a40,40,0,0,1,40-40H392a40,40,0,0,1,40,40V376a40,40,0,0,1-40,40H240c-22.09,0-48-17.91-48-40V336" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></path><polyline points="288 336 368 256 288 176" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></polyline><line x1="80" y1="256" x2="352" y2="256" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></line></svg>
										<span>个人中心</span>
									</a>
									<a class="header__logout" @click="logOut">退出</a>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="header__wrap">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="header__content">
							<form class="header__form" @submit.prevent="submit"><!-- 自定义提交函数 禁用跳转 -->
								<input type="text" class="header__input" placeholder="搜索镜像..." v-model="searchKey">
								<select class="header__select" v-model="cid">
									<option value="0">所有分类</option>
									<option value="26">AI专区</option>
									<option value="1">云镜像</option>
									<option value="2">开发环境</option>
									<option value="3">数据库环境</option>
									<!-- <option value="4">开发工具包</option> -->
									<option value="5">前端开发</option>
									<option value="6">后端开发</option>
									<option value="7">大数据开发</option>
									<option value="25">CMS镜像</option>
								</select>
								<button class="header__btn"><!-- 不要加 type="button" 否则表单无法提交-->
									<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M221.09,64A157.09,157.09,0,1,0,378.18,221.09,157.1,157.1,0,0,0,221.09,64Z" style="fill:none;stroke-miterlimit:10;stroke-width:32px"></path><line x1="338.29" y1="338.29" x2="448" y2="448" style="fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"></line></svg>
								</button>
							</form>

							<div class="header__actions header__actions--2">
								<a href="/fav" class="header__link">
									<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M352.92,80C288,80,256,144,256,144s-32-64-96.92-64C106.32,80,64.54,124.14,64,176.81c-1.1,109.33,86.73,187.08,183,252.42a16,16,0,0,0,18,0c96.26-65.34,184.09-143.09,183-252.42C447.46,124.14,405.68,80,352.92,80Z" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></path></svg>
									<span>收藏夹</span>
								</a>

								<!-- <a href="/cart" class="header__link">
									<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><circle cx="176" cy="416" r="16" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></circle><circle cx="400" cy="416" r="16" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></circle><polyline points="48 80 112 80 160 352 416 352" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></polyline><path d="M160,288H409.44a8,8,0,0,0,7.85-6.43l28.8-144a8,8,0,0,0-7.85-9.57H128" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"></path></svg>
									<span>$00.00</span>
								</a> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { removeToken } from "@/utils/auth";


export default {
  name: "Navbar",
  props: {
    token: String,
  },
  data(){
	return {
		searchKey: this.$route.query.searchKey, 	// 搜索关键词
		cid: this.$route.query.cid ? this.$route.query.cid : 0,			// 搜索分类id
	}
  },
  methods:{
	logOut() {
      removeToken();
	  this.token = null
      // window.location.reload();
      this.$Message.success("退出成功!");
	  if(this.$route.path != '/'){
		this.$router.replace({ 
			path: "/" 
		});
	  }
      
    },
	//判断js字符串是否为空
	empty(str){
		if(str == undefined || str == null || str == false || str == ''){
			return true;
		} else {
			return false;
		}
	},
	//判断两个js数组是否相同
	arrEquip(arr1, arr2){
		if(arr1.length === arr2.length){
			for (let i = 0; i < arr1.length; i++) {
				if (!arr2.includes(arr1[i])) {
					return false
				}
			}
		} else {
			return false
		}
		return true
	},
	submit(){
		let params = []
		if(!this.empty(this.searchKey)){
			params.push("searchKey="+this.searchKey)
		}
		if(!this.empty(this.cid) && this.cid != 0){
			params.push("cid="+this.cid)
		}
		let paramstr = params.join('&')
		//console.log(params)
		//console.log(this.$route.query)
		let curQuery = []
		for(let k in this.$route.query){
			curQuery.push(k+'='+this.$route.query[k])
		}
		//console.log(curQuery)
		//console.log(params)
		//console.log(this.arrEquip(curQuery, params))
		if(!this.arrEquip(curQuery, params)){
			if(this.empty(params)){
				this.$router.replace({ path: "/cate" });
			} else {
				this.$router.replace({ path: "/cate?" + paramstr });
			}
		}
	}
  }
};
</script>

<style scoped>
  
  @import "@/assets/style/bootstrap-grid.min.css";
  @import "@/assets/style/owl.carousel.min.css";
  @import "@/assets/style/main.css";
  
</style>

