import Cookies from "js-cookie";

const TokenKey = "User-Token";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: process.env.VUE_APP_DOMAIN });
}

//删除cookie需要domain 否则无法删除cookie
export function removeToken() {
  return Cookies.remove(TokenKey,{ domain: process.env.VUE_APP_DOMAIN });
}
