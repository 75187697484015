<template>
  <footer class="footer">
		<div class="container">
			<div class="row">
				<div class="col-12">
				</div>
				<div class="col-12">
					<div class="footer__navs">
						<!-- <div class="footer__nav footer__nav--3">
							<div class="footer__contacts">
								<div class="footer__social">
									<a class="" href=""></a>
								</div>
							</div>
						</div> -->
					</div>
					<div class="footer__wrap">
						<div class="footer__desc">
							一键加载镜像 / 开箱即用 / 为开发省时助力
						</div>
						<div class="footer__contacts">
							<span class="footer__copyright">湖南长沙高新开发区尖山路39号中电软件园一期5栋401-409</span>
							<div><span>联系邮箱</span><a class="footer__link" href="mailto:admin@digitalevers.com">admin@digitalevers.com</a></div>
							<span class="footer__copyright">Copyright © 2023. 湖南扳手数据科技有限公司 All rights reserved.</span>
							<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #747ed1;"> 湘ICP备2023016037号-2 </a>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="less" scoped>
  @import "@/assets/style/bootstrap-grid.min.css";
  @import "@/assets/style/main.css";
</style>
