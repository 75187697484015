import Vue from "vue";

const requireContext = require.context("./", true, /index\.vue$/);

requireContext.keys().forEach((filename) => {
  const componentName = filename.slice(2, -10); // 使用文件夹名作为组件名
  const componentConfig = requireContext(filename);
  Vue.component(componentName, componentConfig.default || componentConfig);
});

//console.log('components')