
/**
 * 货币格式化
 * 1000 => ¥ 1,000.00
 * @param {Number,String}
 *
 */
export function currencyFormat(val, locales = "zh-CN", currency = "CNY") {
  return new Intl.NumberFormat(locales, {
    style: "currency",
    currency
  }).format(val);
}
