  const state = {
    isCar:false,
    productList: []
  };

  const getters = {
    getProductList: state => state.productList,
  };
  
  const mutations = {
    ADD_PRODUCT(state, data) {
      state.productList.push(data)
    },
    DEL_PRODUCT(state, index) {
      state.productList.splice(index,1)
    },
    EMPTY_PRODUCT(state){
      //vue2 直接给数组赋值不能做到响应式
      state.productList.splice(0)
    },
    //切换购物车状态 status=true 弹出 status=false 收回
    switchCarStatus(state,status){
      state.isCar = status
    }
  };
  
  const actions = {
    addProduct({commit}, {product_info}) {
      commit("ADD_PRODUCT", product_info);
    },
    // delProduct({ commit }, data) {
    //   commit("DEL_PRODUCT", data);
    // }
  };
  
  export default {
    namespaced: true, // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
    state,
    getters,
    mutations,
    actions
  };
  