import Vue from "vue";
import { getToken } from "@/utils/auth";
import { createOrder } from "@/api/pay.js";

const common_fun = {
    // 判断是否已加入购物车
    if_add_to_cart:function(gid) {
        const _added_cart_games = this.$store.state.cart.gameList;
        const game_exist = _added_cart_games.find(game => game.gid == gid);
        if (game_exist == undefined) {
            return false;
        } else {
            return true;
        }
    },

    //购买支付公共函数——向服务端下单
    /**
     * 
     * @param {*} paytype 支付方式 1 支付宝 2 微信
     * @param {*} payScene 支付场景 1 首页 2 商品详情页 3 购物车
     * @param {*} orderList 订单商品详情
     * @param {*} totalPrice 订单总金额 单位：分
     * @returns 
     */
    async payment(paytype,payScene,orderList,totalPrice) {
        if (!getToken()) {
            this.$Message.error("您还未登录，请先登录");
            this.$store.commit("cart/switchCarStatus", false);
            if (this.$route.path.indexOf("login") >= 0) {
                return;
            }
            setTimeout(() => {
                this.$router.push({ path: "/login" });
            }, 500);
        } else {
            // console.log(orderList)
            // 向服务端传递游戏gid 商品类型和总价
            const cartGameArr = [];
            for (const item of orderList) {
                const _cartGameObj = {
                    "gid": item.gid,
                    "good_type": item.good_type,
                    "vid_id": item.vid,
                    "district_id": item.district_id,
                    "price_final": item.price_final,
                    "price_initial": item.price_initial,
                    "content_id": item.content_id
                };
                cartGameArr.push(_cartGameObj);
            }
            // 构造post数据
            const formData = {
                "cartGameArr": cartGameArr,
                "totalPrice": totalPrice,
                "payType": paytype
            };
            let resData = {};
            await payRequest(formData).then(res => {
                resData = res;
            }, err => {
                resData = err
            });
            return resData
        }
    },
}

//循环挂载到Vue原型中
for(let ex in common_fun){
    Vue.prototype[ex] = common_fun[ex]
}


