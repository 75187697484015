<template>
  <div class="layOut">
    <headers :token="token" :activeItem="this.activeItem" />
    <sideCar :token="token" class="sideCar" />
    <contents  class="main" ref="main"/>
    <layoutFooter v-if="Footer"  class="footer" />
  </div>
</template>

<script>
import { headers, contents, sideCar, layoutFooter } from "./components/index.js";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";
export default {
  name: "Layout",
  components: { headers, contents, sideCar, layoutFooter },
  provide() {
    return {
      tabBarData: [
        { name: "开发省时网", key: "Home" }
        // { name: "赠品", key: "Reward" },
        // { name: "福利资讯", key: "Gift" },
        // { name: "评测", key: "Subject" },
        // { name: "折扣", key: "Discount" },
        // { name: "热门", key: "Hot" }
      ]
    };
  },
  data() {
    return {
      token: getToken(),
      activeItem: ""
    };
  },
  watch: {
    $route(newval) {
      const name = this.$route.name;
      this.activeItem = (this.key).includes(name) ? name : "";
      this.token = getToken();
      // console.log(document.body.scrollTop);
    }
  },
  computed: {
    ...mapGetters("common", ["hasFooter"]),
    Footer() {
      // if (this.hasFooter.includes(true)) {
      //   return true;
      // }
      // TODO 页面页脚问题待解决
      return true;
    },
    key() {
      return this._provided.tabBarData.map(res => res.key);
    }
  }
};
</script>
<style lang="less" scoped>
  .layOut {
    min-height: 100vh;
    position: relative;
    .Navbar {
      position: fixed;
      top: 0;
      z-index: 999;
    }
    .main {
      // padding-bottom: 200px;
    }
    .sideCar {
      position: fixed;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 999;
    }
  }
</style>
