const state = {
  token: "",
  userInfo: {}
};
const getters = {
  token: state => state.token,
  userInfo: state => state.userInfo
};

const mutations = {
  SET_TOKEN(state, data) {
    state.token = data;
  },
  SET_USERINFO(state, data) {
    state.userInfo = data;
  }
};

const actions = {
  setToken({ commit }, data) {
    commit("SET_TOKEN", data);
  },
  setUserinfo({ commit }, data) {
    commit("SET_USERINFO", data);
  }
};

export default {
  namespaced: true, // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
  state,
  getters,
  mutations,
  actions
};
