<template>
    <section>
        <div ref="alipayForm" v-html="payFormPayData" id="alipayForm"></div>
        <Modal v-model="wechatModal" title="微信扫码支付" :loading="loading" @on-cancel="closeWechatWindow()">
            <div class="mod-ct">
                <div class="order"></div>
                <div class="amount">￥{{ price }}</div>
                <div v-if="wechatOrderStatus">支付成功，即将跳转到订单页</div>
                <div v-else class="qr-image" id="qrcode" ref="qrCodeUrl"></div>
                <div class="detail" id="orderDetail">
                    <a href="javascript:void(0)" class="arrow"><i class="ico-arrow-wechat"></i></a>
                </div>
                <div class="tip">
                    <span class="dec dec-left"></span>
                    <span class="dec dec-right"></span>
                    <div class="ico-scan-wechat"></div>
                    <div class="tip-text">
                        <p>请使用微信扫一扫</p>
                        <p>扫描二维码完成支付</p>
                    </div>
                </div>
                <div class="tip-text">
                </div>
            </div>

            <div slot="footer">
                <button type="button" class="ivu-btn ivu-btn-text" @click="closeWechatWindow()">
                    <span>关闭</span>
                </button>
            </div>
        </Modal>
        <!-- 支付延迟转圈动画 -->
        <Spin fix v-if="isSpinShow">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>正在重新支付</div>
        </Spin>
    </section>
</template>

<script>
import { checkWechatOrderStatus } from "@/api/pay.js";
import { rePay } from "@/api/my.js";
import QRCode from 'qrcodejs2'

export default {
  name: 'rePayWindow',
  props: {
    orderInfo:{
        type: Object,
        default: () => {}
    }
  },
  data () {
    return {
        payFormPayData: {},             //支付宝-支付表单数据
        isSpinShow:0,                   //支付下单延迟转圈提示
        wechatModal:false,              //微信扫码支付弹窗面板
        price:0,                        //显示的商品价格
        checkWechatOrderStatusTimer:null, //查询微信订单支付状态定时器
        timerValue:3000,                //定时器时间间隔  单位 毫秒
        oid:'',                         //商户侧订单ID
        wechatOrderStatus:0,            //微信支付订单状态
        qrcode:null,                    //微信支付二维码对象
    }
  },
  created() {
    
  },
  watch: {
    // orderInfo(newVal, oldVal){
        
    // },
    // payFormPayData() {
    //   this.$nextTick(function() {
    //     //console.log("UI渲染完成");
    //     //document.getElementById("alipayForm").submit();
    //     this.$refs.alipayForm.children[0].submit();
    //   });
    // },
  },
  computed: {
    
  },
  methods: {
    //重新发起支付 供父组件调用
    rePay(){
        this.$nextTick(function() {
            if(Object.keys(this.orderInfo).length > 0){
                rePay({'oid':this.orderInfo.id}).then(res=>{
                    //console.log(this.orderInfo)
                    //console.log(res)
                    if(this.orderInfo.pay_type == 1){
                        this.payFormPayData = res.data
                        this.$nextTick(function() {
                            //console.log("UI渲染完成");
                            //document.getElementById("alipayForm").submit();
                            this.$refs.alipayForm.children[0].submit();
                        });
                    } else if(this.orderInfo.pay_type == 2){
                        this.isSpinShow = 0;
                        this.price = res.data.price
                        this.oid = res.data.oid
                        this.wechatModal = true
                        
                        this.$refs.qrCodeUrl.innerHTML = '';
                        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
                            text: res.data.qrcode, // 需要转换为二维码的内容
                            width: 160,
                            height: 160,
                            colorDark: '#555',
                            colorLight: '#ffffff',
                            correctLevel: QRCode.CorrectLevel.H
                        })
                        //微信支付-启动定时查询支付状态
                        this.checkWechatOrderStatusTimer = setInterval(()=>{
                            checkWechatOrderStatus({oid:this.oid}).then(orderStatus=>{
                                this.wechatOrderStatus = parseInt(orderStatus.data)
                                if(this.wechatOrderStatus == 1){
                                    setTimeout(()=>{
                                        this.closeWechatWindow()
                                        window.location.reload();
                                        //this.$router.replace({ path: "/order" });
                                    },2000)
                                }
                            })
                        },this.timerValue)
                    }
                })
            }
        });
    },
    closeWechatWindow(){
        //先关闭定时器 再关闭弹窗
        clearInterval(this.checkWechatOrderStatusTimer)
        this.wechatModal = false
    },
  },
  filters:{
    
  }
}
</script>
<style lang="less" scoped>

.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.pay-container {
	display: flex;
	justify-content: center;
}

.pay-container button {
	margin-top: 10px;
	height: 40px;
}

.pay-container .alipay {
	background: #2ba6df;
	border-color: #2ba6df;
	width: 140px;
	margin-right: 10px;
}

.pay-container .wechat {
	background: #28c445;
	border-color: #28c445;
	width: 140px;
	margin-left: 10px;
}

.pay-container button span {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
}

.pay-container .alipay span,.pay-container .wechat span {
	margin-left: 6px;
}

//wechat pay
.mod-ct {
    // width: 610px;
    padding: 0 35px;
    margin: 0 auto;
    margin-top: 15px;
    background: #fff url("@/assets/img/wave.png") top center repeat-x;
    text-align: center;
    color: #333;
    border: 1px solid #e5e5e5;
    border-top: none
}

.mod-ct .order {
    font-size: 20px;
    padding-top: 30px
}

.mod-ct .amount {
    font-size: 48px;
    margin-top: 20px;
    padding-right: 10px;
}

.mod-ct .qr-image {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.mod-ct .qr-image img {
    width: 230px;
    height: 230px;
}

.mod-ct .detail {
    margin-top: 30px;
    padding-top: 25px
}

.mod-ct .detail .arrow .ico-arrow-wechat {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/wechat-pay.png") -25px -100px no-repeat
}

.mod-ct .detail .arrow .ico-arrow-alipay {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/alipay-pay.png") -25px -100px no-repeat
}

.mod-ct .detail .detail-ct {
    display: none;
    font-size: 14px;
    text-align: right;
    line-height: 28px
}

.mod-ct .detail .detail-ct dt {
    float: left
}

.mod-ct .detail-open {
    border-top: 1px solid #e5e5e5
}

.mod-ct .detail .arrow {
    padding: 6px 34px;
    border: 1px solid #e5e5e5
}

.mod-ct .detail .arrow .ico-arrow {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/wechat-pay.png") -25px -100px no-repeat
}

.mod-ct .detail-open .arrow .ico-arrow {
    display: inline-block;
    width: 20px;
    height: 11px;
    background: url("@/assets/img/wechat-pay.png") 0 -100px no-repeat
}

.mod-ct .detail-open .detail-ct {
    display: block
}

.mod-ct .tip {
    margin-top: 40px;
    border-top: 1px dashed #e5e5e5;
    padding: 30px 0;
    position: relative
}

.mod-ct .tip .ico-scan-wechat {
    display: inline-block;
    width: 56px;
    height: 55px;
    background: url("@/assets/img/wechat-pay.png") 0 0 no-repeat;
    vertical-align: middle;
    *display: inline;
    *zoom: 1
}

.mod-ct .tip .ico-scan-alipay {
    display: inline-block;
    width: 56px;
    height: 55px;
    background: url("@/assets/img/alipay-pay.png") 0 0 no-repeat;
    vertical-align: middle;
    *display: inline;
    *zoom: 1
}
.mod-ct .tip .tip-text {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin-left: 23px;
    font-size: 16px;
    line-height: 28px;
    *display: inline;
    *zoom: 1
}

.mod-ct .tip .dec {
    display: inline-block;
    width: 22px;
    height: 45px;
    background: url("@/assets/img/wechat-pay.png") 0 -55px no-repeat;
    position: absolute;
    top: -23px
}

.mod-ct .tip .dec-left {
    background-position: 0 -55px;
    left: -36px
}

.mod-ct .tip .dec-right {
    background-position: -25px -55px;
    right: -36px
}
@media (max-width:768px) {
    .mod-ct {
        width: 100%;
        padding: 0
    }

    .mod-ct .tip .dec-right {
        display: none
    }

    .mod-ct .detail {
        margin-top: 30px
    }

    .mod-ct .detail .detail-ct {
        padding: 0 19px;
        margin-bottom: 19px
    }
}
</style>