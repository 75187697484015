import axios from "axios";
import { Message } from "view-design";
import { getToken } from "@/utils/auth";

const proxy = process.env.VUE_APP_AJAX_PROXY === "true";

let path = process.env.VUE_APP_BASE_URL.replace("http://","")
path = path.replace("https://","")
const url = window.location.protocol +'//'+ path

// 创建一个axios实例
const service = axios.create({
  baseURL: proxy ? "/api" : url, // url = base url + request url
  withCredentials: true, // 当跨域请求时发送cookie
  timeout: 5000 // 请求超时
});
const token = getToken() || "";


// 请求拦截
service.interceptors.request.use(
  config => {
    if (token) {
      config.headers["user-Token"] = token;
    }
    return config;
  },
  error => {
    // 请求错误时做的操作
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code !== 200) {
      //Message.error(res.msg || "请求失败");
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  error => {
    if ("err" + error === "errError: timeout of 5000ms exceeded") {
      Message.error("请求超时");
    } else {
      Message.error("error" + error || "请求失败");
    }
    return Promise.reject(error);
  }
);

export default service;
