<template>
  <div class="side">
    <div class="fixedBox">
      <div @click="isCar = true">
        <svg-icon size="34" :icon-class="token ? 'cart_warn' : 'cart_garay'" />
        <div class="cartCountCon" v-if="orderList.length > 0">
          <span class="cartCount">{{orderList.length}}</span>
        </div>
      </div>
      <div @click="kefu()">
        <svg-icon size="34" :icon-class="token ? 'kefu_warn' : 'kefu'" />
      </div>
    </div>
    <Drawer placement="right" width="340" :closable="false" v-model="isCar">
      <template slot="header">
        我的购物车：共 <span style="color: #ff7400; margin: 6px">{{orderList.length}}</span> 个下载
      </template>
      <template v-if="orderList.length > 0">
        <div class="main">
          <div class="order" v-for="(item, index) in orderList" :key="index">
            <div class="conten">
              <div class="top">
                <img :src="item.product_image" />
              </div>
              <div class="center">
                <div class="gameName"><span>{{item.product_cname}}</span><span></span></div>
                <!-- <div class="desc overflow">{{item.version_cname}} / {{item.district}} / {{item.product_type_name}}</div> -->
              </div>

              <div class="bottom">
                <div class="rate">- {{item.off}}%</div>
                <div>
                  <div class="price">￥{{item.final_price}}</div>
                  <div class="oldPrice">￥{{item.initial_price}}</div>
                </div>

              </div>
            </div>
            <div class="icon" @click="deleteOrder(item)">
              <svg-icon size="26" icon-class="delete" />
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="total">
            <span>合计：</span>
            <span class="money">￥<i>{{totalPrice}}</i></span>
          </div>
          <div class="payway">
            <!-- paypal -->
            <!-- <Button class="paypal" type="warning" @click="pay('paypal')">
              <svg-icon icon-class="paypal" class="paypal-icon"/>
            </Button> -->

            <!-- alipay && wechat -->
            <div class="pay-container">
              <div ref="alipayForm" v-html="alipayFormPayData" id="alipayForm"></div>
              <Button class="alipay" type="success"  @click="payAtCart(1)">
                <svg-icon size="25" icon-class="alipay" />
                <span>支付宝</span>
              </Button>
              <Button class="weixin" type="success"  @click="payAtCart(2)">
                <svg-icon size="25" icon-class="wechat_pay" />
                <span>微信支付</span>
              </Button>
            </div>
            <!-- <div id="paypal" ref="paypal"></div> --> <!-- 暂时关闭paypal支付-->
          </div>
        </div>
      </template>
      <div v-else class="cart_empty">
        <svg-icon size="150" icon-class="cart_empty" />
        <div class="gotobuy" @click="gotobuy">再逛逛</div>
      </div>
      <!-- 支付延迟转圈动画 -->
      <Spin fix v-if="isSpinShow">
          <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
          <div>正在支付下单</div>
      </Spin>
    </Drawer>
    <Modal v-model="kefumodal" title="微信扫一扫联系我们" :footerHide = false width="320" style="text-align: center;">
        <img :src="kefuImgSrc" width="200px" height="200px"/>
        <div slot="footer"></div>
    </Modal>
    <Modal v-model="wechatModal" title="微信扫码支付" :loading="loading" @on-cancel="closeWechatWindow()">
            <div class="mod-ct">
                <div class="order"></div>
                <div class="amount">￥{{ price }}</div>
                <div v-if="wechatOrderStatus">支付成功，即将跳转到订单页</div>
                <div v-else class="qr-image" id="qrcode" ref="qrCodeUrl"></div>
                <div class="detail" id="orderDetail">
                    <a href="javascript:void(0)" class="arrow"><i class="ico-arrow-wechat"></i></a>
                </div>
                <div class="tip">
                    <span class="dec dec-left"></span>
                    <span class="dec dec-right"></span>
                    <div class="ico-scan-wechat"></div>
                    <div class="tip-text">
                        <p>请使用微信扫一扫</p>
                        <p>扫描二维码完成支付</p>
                    </div>
                </div>
                <div class="tip-text">
                </div>
            </div>

            <div slot="footer">
                <button type="button" class="ivu-btn ivu-btn-text" @click="closeWechatWindow()">
                    <span>关闭</span>
                </button>
            </div>
        </Modal>
  </div>
</template>

<script>
import { createOrder, checkWechatOrderStatus } from "@/api/pay.js";
import QRCode from 'qrcodejs2'

export default {
  name: "SideCar",
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  components: {

  },
  data() {
    return {
      wechatModal:false,
      isCar: false, // 侧边栏显示隐藏
      kefumodal: false, // 客服微信二维码弹窗
      kefuImgSrc:"",    //点击加载客服图片
      isSpinShow:0,   //支付下单延迟转圈提示
      orderList: this.$store.state.cart.productList,
      // orderListLength:this.$store.state.cart.gameList.length, // 这样并不能监听到数组长度的变化
      alipayFormPayData: null,
      wechatModal:false,              //微信扫码支付弹窗面板
      checkWechatOrderStatusTimer:null, //查询微信订单支付状态定时器
      timerValue:3000,                //定时器时间间隔  单位 毫秒
      oid:'',                         //商户侧订单ID
      wechatOrderStatus:0,             //微信支付订单状态
      qrcode:null,                    //微信支付二维码对象
    };
  },
  watch: {
    // 这样才能监听到数组长度的变化
    "orderList.length": {
      handler(varNew, varOld) {
        // console.log(varNew)
        // console.log(varOld)
        this.$nextTick(() => {
          if (varNew == 1 && varOld == 0) {
            this.setLoaded();
          }
        });
      }
    }

  },
  mounted() {

  },
  computed: {
    totalPrice() {
      let _totalPrice = 0;
      if (this.orderList.length > 0) {
        for (const item of this.orderList) {
          _totalPrice += parseFloat(item.final_price);
        }
      }
      return parseFloat(_totalPrice).toFixed(2);
    }
  },
  methods: {
    setLoaded() {
      
    },
    closeWechatWindow(){
        this.wechatModal = false
        clearInterval(this.checkWechatOrderStatusTimer)
    },
    payAtCart(payType) {
      if (!this.token) {
        this.$Message.error("您还未登录，请先登录");
        this.isCar = false;
        if (this.$route.path.indexOf("login") !== -1) {
          return;
        }
        setTimeout(() => {
          this.$router.replace({ path: "/login" });
        }, 1000);
      } else {
        // console.log(this.orderList)
        // 向服务端传递 商品gid 商品类型和总价
        const cartGoodArr = [];
        for (const item of this.orderList) {
          cartGoodArr.push(item.pid);
        }
        // 构造post数据
        const createOrderData = {
          "pid": cartGoodArr.join(','),
          "totalPrice": this.totalPrice,
          "payType": payType,
          "payScene":3,
        };

        this.isSpinShow = 1
        createOrder(createOrderData).then(res => {
          //接口返回后关闭spin转圈提示
          this.isSpinShow = 0
          this.isCar = false
          // if (paytype == 1 || paytype == 2) {
          //   // 直接提交form表单会出现数据为空的情况 因为vue的双向绑定有一定的时间延迟 在数据还没反映到表单上去的时候就立即进行提交就会出现数据为空的情况，使用settimeout是一种不优雅的方式
          //   // 最好的方法是在watch中使用 this.$nextTick 进行数据渲染完成的监听
          //   // setTimeout(() => {
          //   //   document.getElementById('alipayForm').submit();
          //   // }, 500);

          // } else {
          //   //
          // }
          if(payType == 1){
              this.alipayFormPayData = res.data;                     // 渲染支付页面
              // 防抖避免重复支付
              this.$nextTick(() => {
                  this.$store.commit("cart/EMPTY_PRODUCT");
                  this.$refs.alipayForm.children[0].submit();     // 提交支付宝支付表单
              });
          } else if(payType == 2){
              this.isSpinShow = 0;
              this.price = res.data.price
              this.oid = res.data.oid
              this.wechatModal = true

              this.$refs.qrCodeUrl.innerHTML = '';
              this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
                  text: res.data.qrcode, // 需要转换为二维码的内容
                  width: 160,
                  height: 160,
                  colorDark: '#555',
                  colorLight: '#ffffff',
                  correctLevel: QRCode.CorrectLevel.H
              })
              
              //微信支付-启动定时查询支付状态
              this.checkWechatOrderStatusTimer = setInterval(()=>{
                  checkWechatOrderStatus({oid:this.oid}).then(orderStatus=>{
                      this.wechatOrderStatus = parseInt(orderStatus.data)
                      if(this.wechatOrderStatus == 1){
                          setTimeout(()=>{
                              this.closeWechatWindow()
                              this.$store.commit("cart/EMPTY_PRODUCT");
                              this.$router.replace({ path: "/order" });
                          },2000)
                      }
                  })
              },this.timerValue)
          }
        }, err => {
          this.isSpinShow = 0
          this.$store.commit("cart/EMPTY_PRODUCT");
          this.$Message.error({
            content:err.msg,
            onClose:function(){
                window.location.reload()
            }
          })
        });
      }
    },
    
    deleteOrder(product_info) {
      // 删除购物车使用 findIndex先查找对象在对象数组中的索引值index 然后使用splice删除
      const _added_cart_products = this.$store.state.cart.productList;
      const product_index = _added_cart_products.findIndex(product => product.pid == product_info.pid);
      if (product_index > -1) {
        this.$store.commit("cart/DEL_PRODUCT", product_index);
      } else {
        console.log("删除错误");
      }
    },
    //购物车为空时去选购
    gotobuy() {
      this.isCar = false;
      if (this.$route.path != "/") {
        this.$router.replace({ path: "/" });
      }
    },
    //客服弹窗
    kefu(){
      this.kefumodal = true
      this.kefuImgSrc = "/img/kefu.png"
    }

  }
};
</script>

<style lang="less" scoped>
  @import "index.less";
</style>
