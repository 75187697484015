import Vue from "vue";

Vue.mixin({
  data() {
    return {};
  },
  methods: {
  },
  destroyed() {
    // console.log("执行destroyed", "mixins");
  }
});
