<template>
  <button :class="[type,size]" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "warn"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "small"
    }
  }
};
</script>

<style lang="less" scoped>
  button {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 6px;
    cursor: pointer;
    font-size: 18px;
  }
  .warn {
    color: #fff;
    background: @warn;
    border: 1px solid @warn;
  }
  .warn-1 {
    background: transparent;
    border: 1px solid @warn;
    color: @warn;
  }
  .success {
    color: #fff;
    background: @success;
    border: 1px solid @success;
  }
  .success-1 {
    background: transparent;
    border: 1px solid @success;
    color: @success;
  }
  .white {
    background: #fff;
    border: 1px solid #fff;
  }
  .white-1 {
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
  }
  .grey {
    color: #fff;
    background: @grey;
    border: 1px solid @grey;
  }
  .grey-1 {
    background: transparent;
    border: 1px solid @grey;
    color: @grey;
  }
  .medium {
    width: 100%;
    height: 80px;
  }
  .small {
    width: 130px;
    height: 49px;
  }
  .mini {
    font-size: 16px;
    width: 60px;
    height: 24px;
  }
</style>
