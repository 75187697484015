const state = {
  hasFooter: []
};
const getters = {
  hasFooter: state => state.hasFooter
};

const mutations = {
  SET_SLIDESHOW(state, data) {
    state.hasFooter = data;
  }
};

const actions = {
  setSlideshow({ commit }, data) {
    commit("SET_SLIDESHOW", data);
  }
};

export default {
  namespaced: true, // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
  state,
  getters,
  mutations,
  actions
};
