import request from "@/utils/request";

/**
 * 获取用户订单数据
 */
 export function getOrders(data) {
  return request({
    url: "/my/orders",
    method: "post",
    data
  });
}

/**
 * 获取用户购物车订单数据
 */
export function getExtraOrders(data) {
  return request({
    url: "/my/extraOrder",
    method: "post",
    data
  });
}

/**
 * 重新支付获取订单参数
 */
 export function rePay(data) {
  return request({
    url: "/my/repay",
    method: "post",
    data
  });
}

/**
 * 取消订单
 */
 export function cancelOrder(data) {
  return request({
    url: "/my/cancelOrder",
    method: "post",
    data
  });
}

/**
 * 获取下载镜像文件链接
 */
export function download(data) {
  return request({
    url: "/my/download",
    method: "post",
    data
  });
}


/**
 * 收藏数据
 */
export function getFavData(data) {
  return request({
    url: "/my/favData",
    method: "post",
    data
  });
}


/**
 * 添加到收藏
 */
export function addToFav(data) {
  return request({
    url: "/my/addToFav",
    method: "post",
    data
  });
}

/**
 * 取消收藏
 */
export function delFav(data) {
  return request({
    url: "/my/delFav",
    method: "post",
    data
  });
}
