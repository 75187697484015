import request from "@/utils/request";

/**
 * 支付下单
 */
export function createOrder(params) {
  return request({
    url: "/pay/createOrder",
    method: "post",
    params
  });
}

/**
 * 微信订单状态查询
 */
export function checkWechatOrderStatus(params) {
  return request({
    url: "/pay/checkWechatOrderStatus",
    method: "post",
    params
  });
}


/**
 * 首页添加免费镜像订单
 */
export function addFreeProduct(params) {
  return request({
    url: "/pay/addFreeProduct",
    method: "post",
    params
  });
}
